import React, { FC, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBackgroundColor, useResponsiveType } from "../../utils";
import { LinkType } from "../../types";
import LinkTo from "../linkTo/LinkTo";
import { ArrowRight, ChevronDown, Padlock } from "../icon";
import Typography from "../typography/Typography";
import Link from "../link/Link";
import { Button } from "../button";
import { media } from "../../styles/tokens";
import { useDataLayerPush } from "../../hooks/useDataLayerPush";

// Interface

export interface NavigationProps {
  isActive: boolean;
  items?: NavItemProps[];
  ctaButton?: LinkType;
  setOverlayActive?: (overlayActive: boolean) => void;
  setIsMenuOpen?: (isMenuOpen: boolean) => void;
}

export interface NavItemProps {
  link: LinkType;
  childItems?: ChildNavItem[];
  ctaButton?: LinkType;
  isActive?: boolean;
  setActiveItem?: (linkUrl: string) => void;
  setOverlayActive?: (overlayActive?: boolean) => void;
  navItemIndex?: number;
  setIsMenuOpen?: (isMenuOpen: boolean) => void;
}

export interface ChildNavItem {
  link: LinkType;
  text?: string;
  childItems?: LinkType[];
}

interface StyledTopLevelButtonProps {
  isActive?: boolean;
}

interface StyledNavigationProps {
  isActive?: boolean;
}

interface StyledDropdownProps {
  isActive?: boolean;
}

// Styles

const StyledNavigation = styled.nav<StyledNavigationProps>`
  ${({ theme: { space, colors, media, maxMedia, layers }, isActive }) => css`
    @media (max-width: ${maxMedia.large}) {
      /* border-bottom: ${isActive ? "1px solid currentColor;" : "none"}; */
      position: absolute;
      top: calc(100%);
      left: 0;
      width: 100vw;
      z-index: ${layers.navigationDropdown};
      transition: all 0.5s ease-in-out;
      display: ${isActive ? "block" : "none"};
      pointer-events: ${isActive ? "auto" : "none"};
      overflow: auto;
      ${useBackgroundColor("white")}

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
      }

      @supports not (min-height: calc(100dvh - 96px)) {
        height: calc(100vh - var(--header-height));

        padding: ${isActive
          ? `${space.small} ${space.large} ${space.large} ${space.large}`
          : `0 ${space.small} 0 ${space.small}`};
      }

      @supports (min-height: calc(100dvh - var(--header-height))) {
        height: calc(100dvh - var(--header-height));

        padding: ${isActive
          ? `${space.small} ${space.large}`
          : `0 ${space.large}`};
      }
    }

    @media (max-width: ${maxMedia.medium}) {
      @supports not (min-height: calc(100dvh - var(--header-height))) {
        padding: ${isActive
          ? `${space.small} ${space.small} ${space.xxxLarge}
            ${space.small}`
          : `0 ${space.small} 0
            ${space.small}`};
      }

      @supports (min-height: calc(100dvh - var(--header-height))) {
        padding: ${isActive ? space.small : `0 ${space.small}`};
      }
    }
  `};
`;

const StyledNavItems = styled.ul`
  ${({ theme: { space, colors, media, borderRadii, maxMedia } }) => css`
    @media (max-width: ${maxMedia.large}) {
      border: solid 1px currentColor;
      border-radius: ${borderRadii.small};
      overflow: hidden;
    }

    @media (min-width: ${media.large}) {
      display: flex;
      gap: ${space.xxxSmall};
    }
  `};
`;

const StyledNavItem = styled.li`
  ${({ theme: { space, colors, media, maxMedia } }) => css`
    @media (max-width: ${maxMedia.large}) {
      & + & {
        border-top: 1px solid currentColor;
      }
    }
    @media (min-width: ${media.large}) {
      position: relative;
    }
  `};
`;

const StyledTopLevelButton = styled.button<StyledTopLevelButtonProps>`
  ${({ theme: { space, colors, media, borderRadii }, isActive }) => css`
    ${useBackgroundColor(isActive ? "darkBlue" : "white")}
    ${useResponsiveType("bodyLargeBold")}
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${space.xSmall};

    svg {
      transition: transform 0.3s;
      transform: rotate(${isActive ? "180deg" : "0deg"});
    }

    @media (min-width: ${media.large}) {
      gap: ${space.xxxxxSmall};
      padding: ${space.xxxSmall} ${space.xxSmall};
      border-radius: ${borderRadii.small};
      position: relative;
      ${useBackgroundColor("darkBlue")}

      &:hover {
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          height: 65px;
          width: 100%;
          left: 0;
          z-index: 9999999;
        }
      }
    }
  `};
`;

const StyledTopLevelLink = styled(LinkTo)`
  ${({ theme: { space, colors, media, borderRadii } }) => css`
    ${useResponsiveType("bodyLargeBold")}
    padding: ${space.xSmall};
    display: block;
    text-decoration: none;

    @media (min-width: ${media.large}) {
      padding: ${space.xxxSmall} ${space.xxSmall};
      border-radius: ${borderRadii.small};
    }
  `};
`;

const StyledDropdown = styled.div<StyledDropdownProps>`
  ${({ theme: { space, colors, borderRadii, media, layers }, isActive }) => css`
    display: grid;
    transition: all 0.5s ease-in-out;
    grid-template-rows: ${isActive ? "1fr" : "0fr"};

    > div {
      transition: all 0.5s ease-in-out;
      overflow: hidden;
      padding-top: ${isActive ? space.fluidSmallToMedium : 0};
      padding-bottom: ${isActive ? space.fluidSmallToMedium : 0};
      padding-left: ${space.fluidSmallToMedium};
      padding-right: ${space.fluidSmallToMedium};
      ${useBackgroundColor("white")}
    }

    @media (min-width: ${media.large}) {
      > div {
        border: ${isActive ? "1px solid currentColor" : "none"};
        border-radius: ${borderRadii.small};
      }

      position: absolute;
      top: calc(100% + 64px);
      left: 0;
      width: 440px;
      z-index: ${layers.navigationDropdown};
    }
  `};
`;

const StyledDropdownItem = styled.div`
  ${({ theme: { space, colors, media } }) => css`
    a {
      text-decoration: none;
    }
    & + & {
      margin-top: ${space.fluidSmallToMedium};
      padding-top: ${space.fluidSmallToMedium};
      border-top: 1px solid currentColor;
    }
  `};
`;

const StyledDropdownItemHeading = styled(Typography)`
  ${({ theme: { space, colors, media } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${space.xxxSmall};
  `};
`;

const StyledDropdownCtaButtons = styled.div`
  ${({ theme: { space, colors, media, maxMedia } }) => css`
    margin-top: ${space.fluidMediumToLarge};

    @media (max-width: ${maxMedia.large}) {
      a {
        &:last-of-type {
          display: none;
        }
      }
    }

    @media (min-width: ${media.large}) {
      a {
        width: 100%;

        &:first-of-type {
          display: none;
        }
      }
    }
  `};
`;

const StyledDropdownItemSecondaryHeading = styled(Typography)`
  ${({ theme: { space, colors, media } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

const StyledDropDownItemChildren = styled.ul`
  ${({ theme: { space, colors, media } }) => css`
    margin-top: ${space.small};

    a {
      ${useResponsiveType("body")}

      &:hover {
        text-decoration: underline;
      }
    }

    > * + * {
      margin-top: ${space.small};
    }

    @media (min-width: ${media.large}) {
      > * + * {
        margin-top: ${space.xSmall};
      }
    }
  `};
`;

const StyledCtaButton = styled(Button)`
  ${({ theme: { space, colors, media } }) => css`
    margin-top: ${space.medium};
    width: 100%;

    @media (min-width: ${media.large}) {
      display: none;
    }
  `};
`;

const StyledLink = styled(LinkTo)`
  ${({ theme: { space, colors, media } }) => css`
    display: block;

    &:hover {
      *:first-of-type {
        text-decoration: underline;
      }
    }
  `};
`;

// JSX

const NavItem: FC<NavItemProps> = ({
  link,
  childItems,
  ctaButton,
  isActive,
  setActiveItem,
  setOverlayActive,
  navItemIndex,
  setIsMenuOpen,
}) => {
  const { dataLayerPush } = useDataLayerPush();
  const hasChildItems = childItems && childItems.length > 0;
  const navItemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const focusableElements = navItemRef.current?.querySelectorAll(
      'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const lastFocusableElement =
      focusableElements && focusableElements[focusableElements.length - 1];

    const handleLastElementBlur = () => {
      if (setActiveItem) {
        setActiveItem("");
      }
    };

    if (lastFocusableElement) {
      lastFocusableElement.addEventListener("blur", handleLastElementBlur);
    }

    return () => {
      if (lastFocusableElement) {
        lastFocusableElement.removeEventListener("blur", handleLastElementBlur);
      }
    };
  }, [navItemRef, setActiveItem]);

  const handleButtonClick = () => {
    dataLayerPush({
      event: "navigation_dropdown_button_click",
      navigationDropdownButton: link?.label,
    });
    if (setActiveItem) {
      setActiveItem(link?.url);
    }
  };

  const handleMouseEnter = () => {
    const largeMediaWidth = parseInt(media.large.replace("px", ""));

    if (window.innerWidth > largeMediaWidth && hasChildItems) {
      if (setActiveItem) {
        dataLayerPush({
          event: "navigation_dropdown_hover",
          navigationDropdown: link?.label,
        });
        setActiveItem(link?.url);
      }
    }
  };

  const handleMouseLeave = () => {
    const largeMediaWidth = parseInt(media.large.replace("px", ""));
    if (window.innerWidth > largeMediaWidth && hasChildItems) {
      if (setActiveItem) {
        setActiveItem("");
      }
    }
  };

  // Set overlay to active when the navigation is active

  useEffect(() => {
    if (setOverlayActive) {
      setOverlayActive(isActive);
    }
  }, [isActive, setOverlayActive]);

  return (
    <StyledNavItem
      ref={navItemRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hasChildItems ? (
        <StyledTopLevelButton
          onClick={handleButtonClick}
          type="button"
          isActive={isActive}
          id={`navigation-dropdown-trigger-${navItemIndex}`}
        >
          {link?.label} <ChevronDown />
        </StyledTopLevelButton>
      ) : (
        <StyledTopLevelLink
          to={link?.url}
          onClick={() => (setIsMenuOpen ? setIsMenuOpen(false) : "")}
        >
          {link?.label}
        </StyledTopLevelLink>
      )}
      {hasChildItems && (
        <StyledDropdown isActive={isActive}>
          <div>
            {childItems?.map(({ link, text, childItems }, childItemIndex) => (
              <StyledDropdownItem key={childItemIndex}>
                {text ? (
                  <StyledLink
                    to={link?.url}
                    target={link?.target}
                    onClick={() => {
                      dataLayerPush({
                        event: "navigation_dropdown_item_click",
                        navigationDropdownItem: link?.label,
                      });
                      setActiveItem ? setActiveItem("") : "";
                      setIsMenuOpen ? setIsMenuOpen(false) : "";
                    }}
                  >
                    <StyledDropdownItemHeading
                      component="span"
                      variant="bodyLargeBold"
                    >
                      {link?.label}
                      <ArrowRight />
                    </StyledDropdownItemHeading>
                    {text && (
                      <Typography component="span" variant="body">
                        {text}
                      </Typography>
                    )}
                  </StyledLink>
                ) : (
                  <>
                    {link?.url === "#" ? (
                      <Typography variant="bodyLargeBold" component="h4">
                        {link.label}
                      </Typography>
                    ) : (
                      <StyledLink
                        to={link?.url}
                        target={link?.target}
                        onClick={() => {
                          dataLayerPush({
                            event: "navigation_dropdown_item_click",
                            navigationDropdownItem: link?.label,
                          });
                          setActiveItem ? setActiveItem("") : "";
                          setIsMenuOpen ? setIsMenuOpen(false) : "";
                        }}
                      >
                        <StyledDropdownItemSecondaryHeading
                          component="span"
                          variant="bodyLargeBold"
                        >
                          {link?.label} <ArrowRight />
                        </StyledDropdownItemSecondaryHeading>
                      </StyledLink>
                    )}
                  </>
                )}
                {childItems && childItems.length > 0 ? (
                  <StyledDropDownItemChildren>
                    {childItems
                      ?.filter((item) => !!item)
                      .map(({ url, target, label }, index) => (
                        <li key={index}>
                          <StyledLink
                            key={index}
                            to={url}
                            target={target}
                            onClick={() => {
                              dataLayerPush({
                                event: "navigation_dropdown_item_click",
                                navigationDropdownItem: label,
                              });
                              setActiveItem ? setActiveItem("") : "";
                              setIsMenuOpen ? setIsMenuOpen(false) : "";
                            }}
                          >
                            <Typography component="span" variant="bodyMedium">
                              {label}
                            </Typography>
                          </StyledLink>
                        </li>
                      ))}
                  </StyledDropDownItemChildren>
                ) : (
                  ""
                )}
              </StyledDropdownItem>
            ))}
            {ctaButton && (
              <StyledDropdownCtaButtons>
                <Link
                  {...ctaButton}
                  onClick={() => {
                    setActiveItem ? setActiveItem("") : "";
                    setIsMenuOpen ? setIsMenuOpen(false) : "";
                  }}
                />
                <Button
                  variant="neutral"
                  arrowRight
                  size="small"
                  onClick={() => {
                    setActiveItem ? setActiveItem("") : "";
                    setIsMenuOpen ? setIsMenuOpen(false) : "";
                  }}
                  dataLayerEvent="navigation_dropdown_cta_button_click"
                  {...ctaButton}
                />
              </StyledDropdownCtaButtons>
            )}
          </div>
        </StyledDropdown>
      )}
    </StyledNavItem>
  );
};

export const Navigation: FC<NavigationProps> = ({
  isActive,
  items,
  ctaButton,
  setOverlayActive,
  setIsMenuOpen,
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const handleItemClick = (linkUrl: string) => {
    setActiveItem(activeItem === linkUrl ? null : linkUrl);
  };

  return (
    <StyledNavigation aria-label="main-navigation" isActive={isActive}>
      <div>
      <StyledNavItems>
        {items?.map((item, index) => (
          <NavItem
            key={index}
            {...item}
            isActive={activeItem === item?.link?.url}
            setActiveItem={handleItemClick}
            setOverlayActive={setOverlayActive}
            setIsMenuOpen={setIsMenuOpen}
            navItemIndex={index + 1}
          />
        ))}
      </StyledNavItems>
      {ctaButton && (
        <StyledCtaButton
          variant="lime"
          iconLeft={<Padlock size="small" />}
          dataLayerEvent="header_mobile_cta_button_click"
          {...ctaButton}
        />
      )}
      </div>
    </StyledNavigation>
  );
};

export default Navigation;
